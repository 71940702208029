/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.tooltip {
  font-size: 10px;
}
.warning-snackbar {
  background: #e95946;
}
.success-snackbar {
  background: #7fa053;
}

.markerdiv {
  z-index: 1035;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 15px;
  left: 15px;
  min-height: 50px;
  opacity: 0.9 !important;
  color: #fff;
  background-color: #343a40 !important;
}

.obsinfo {
  color: #fff;
  padding-left: 44px;
  padding-top: 13px;
  padding-bottom: 3px;
  opacity: 0.97 !important;
  font-size: 14px;
  position: absolute;
  top: 70px;
  left: 10px;
  right: 10px;
  z-index: 1200;
  background-color: #343a40 !important;
}
.markerdata_icons_left {
  z-index: 1005;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  left: 4px;
  cursor: pointer;
}
.markerdatatext {
  z-index: 1005;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 2px;
  left: 40px;
  font-size: 12px;
}
.markerdata_icons {
  z-index: 1005;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.markerdata_icons i {
  padding-right: 7px;
}
.markerdataicon {
  font-size: 25px;
}
.obsinfo {
  border-top: 1px solid #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.obsinfoheader {
  font-size: 11px;
  text-align: right;
  padding-right: 4px;
  color: #888b71;
}

.obsinfodata {
  padding-left: 2px;
  font-size: 13px;
  text-align: left;
  color: #fff;
}

.obsinfoauctor {
  font-size: 11px;
  padding-left: 4px;
  color: #888b71;
}

.topsettingicon {
  font-size: 20px;
  color: #fff;
}

.settingdiv {
  z-index: 1035;
  padding: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  right: 15px;
  left: 15px;
  min-height: fit-content;
  opacity: 0.9 !important;
  color: #fff;
  background-color: #343a40 !important;
}
.sbflogo {
  color: #fff;
  z-index: 1001;
  position: absolute !important;
  bottom: 50px;
  right: 10px;
  width: 82px;
  font-size: 25px;
  opacity: 0.8;
  text-align: center;
  border: 1px solid #343a40;
  background-color: #343a40 !important;
}
.mapbuttons {
  color: #fff;
  z-index: 1001;
  position: absolute !important;
  top: 75px;
  left: 8px;
  width: 40px;
  font-size: 25px;
  opacity: 0.8;
  text-align: center;
  background-color: #343a40 !important;
  padding: 5px;
}
.mapbutton {
  color: #fff;
  font-size: 25px;
}

.logindiv {
  z-index: 1035;
  padding: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  right: 15px;
  left: 15px;
  min-height: fit-content;
  opacity: 0.9 !important;
  color: #fff;
}
.filterdiv {
  z-index: 1035;
  padding: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  right: 15px;
  left: 15px;
  min-height: fit-content;
  opacity: 0.9 !important;
  color: #fff;
}

.obsdiv {
  z-index: 1035;
  padding: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 10px;
  right: 15px;
  left: 15px;
  min-height: fit-content;
  opacity: 0.95 !important;
  color: #fff;
  background-color: #343a40 !important;
}
.obsdivobsar {
  max-height: 70vmax;
  overflow: auto;
  padding-right: 0px;
}
.obstblobsar td {
  border-bottom: 1px solid lightgrey;
}
.topmenu {
  background-color: #343a40 !important;
}
.topicons {
  position: absolute;
  right: 4px;
  top: 5px;
  margin: 3px;
  display: inline-block;
}
.topantalobs {
  color: #fff;
  border: 1px solid lightgrey;
  border-radius: 3px;
  padding: 2px;
  display: inline-flex;
}
.topplacetracker {
  color: lightgrey;
  animation: blink 2s ease-in infinite;
  display: inline-flex;
  padding-right: 5px;
}
@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.vnamn {
  color: #888b71;
}

.atlasLegend {
  color: #fff;
  z-index: 1001;
  position: absolute !important;
  bottom: 50px;
  right: 10px;
  width: 120px;
  font-size: 25px;
  opacity: 0.8;
  text-align: left;
  border: 1px solid #343a40;
  background-color: #343a40 !important;
  padding: 5px;
}
.atlasLegendText {
  color: white;
  font-size: small;
  padding-left: 4px;
}

.atlasLegendColor {
  width: 15px;
  height: 15px;
  border: 1px;
  border-radius: 8px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
